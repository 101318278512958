/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import male from "./images/male.svg";
import female from "./images/female.svg";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    marginRight: "2%",
    color: "#04a49c",
  },
  timeStamp: {
    color: "#04a49c",
  },
}));

export default function ItemList(props) {
  const classes = useStyles();

  return (
    <List>
      {props.project ? (
        props.project.segments.map((segment, i) => {
          return (
            <div key={i}>
              <ListItem>
                <ListItemAvatar className={classes.avatar}>
                  <Typography variant="overline" display="block" gutterBottom>
                    Speaker {segment.speaker.slice(4, 5)}
                    {segment.speaker.includes("f") ? (
                      <img width="5%" height="5%" src={female} />
                    ) : (
                      <img width="5%" height="5%" src={male} />
                    )}
                  </Typography>
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="body2" gutterBottom>
                    {segment.text}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    className={classes.timeStamp}
                  >
                    {segment.start}-{segment.end}
                  </Typography>
                </ListItemText>
              </ListItem>
              <Divider />
            </div>
          );
        })
      ) : (
        <></>
      )}
    </List>
  );
}
