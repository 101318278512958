import {
  GET_LANGUAGES,
  GET_PROJECTS,
  GET_PROJECT,
} from "../actions/actionTypes";

const initialState = {
  languages: [],
  projects: [],
  project: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
      };

    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    case GET_PROJECT:
      return {
        ...state,
        project: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
