import { GET_PROJECT, GET_LANGUAGES, GET_PROJECTS } from "./actionTypes";

import axios from "axios";
export const apiEndpoint = `https://diarization-api.tarjama.com/api/v1`;
export const getProject = (projectID) => async (dispatch) => {
  try {
    const res = await axios.get(`${apiEndpoint}/projects/${projectID}`);
    const project = res.data;
    dispatch({
      type: GET_PROJECT,
      payload: project,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getLanguages = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiEndpoint}/languages/`);
    const languages = res.data;
    dispatch({
      type: GET_LANGUAGES,
      payload: languages,
    });
  } catch (error) {
    console.error(error);
  }
};

export const getProjects = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiEndpoint}/projects/`);
    const projects = res.data;
    dispatch({
      type: GET_PROJECTS,
      payload: projects,
    });
  } catch (error) {
    console.error(error);
  }
};
