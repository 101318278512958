import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "./images/logo.svg";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useSelector, useDispatch } from "react-redux";
import Project from "./Project";
import ItemList from "./ItemList";
import Container from "@material-ui/core/Container";
import { post } from "axios";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getProjects, apiEndpoint } from "./store/actions";
import VideoPlayer from "./VideoPlayer";
import Loading from "./Loading";

export default function App() {
  const classes = useStyles();
  const [fileObject, setFileObject] = React.useState();
  const [language, setLanguage] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };
  const handleChangeFile = (event) => {
    setFileObject(event.target.files[0]);
  };
  const languages = useSelector((state) => state.files.languages);
  const projects = useSelector((state) => state.files.projects.results);
  const project = useSelector((state) => state.files.project);
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <AppBar position="static" color="white">
        <Toolbar>
          <Button
            className={classes.buttonIcon}
            startIcon={<img src={logo}></img>}
          ></Button>
        </Toolbar>
      </AppBar>
      <Container fixed>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Language</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={language}
                onChange={handleChange}
                MenuProps={MenuProps}
              >
                {languages ? (
                  languages.map((language) => {
                    return (
                      <MenuItem value={language.id}>{language.title}</MenuItem>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>

            <input
              accept="video/*"
              className={classes.input}
              id="contained-button-file"
              onChange={handleChangeFile}
              multiple
              type="file"
            />
            <label htmlFor="contained-button-file">
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                component="span"
              >
                Add File
              </Button>
            </label>

            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={async function () {
                let form_data = new FormData();
                form_data.append("media_file", fileObject);
                form_data.append("language", language);
                console.log(form_data);
                setLoading(true);
                await post(`${apiEndpoint}/projects/`, form_data, {
                  headers: {
                    "Content-Type": "multipart/form-data; charset=utf-8;",
                  },
                });
                setLoading(false);
                dispatch(getProjects());
              }}
            >
              Submit File
            </Button>
            {fileObject ? (
              <Typography
                className={classes.text}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {fileObject.name}{" "}
              </Typography>
            ) : (
              <></>
            )}
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <Loading />
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <Container
              style={{ maxHeight: 400, overflow: "auto" }}
              className={classes.container}
            >
              {projects ? (
                projects.map((project) => {
                  return <Project key={project.id} project={project} />;
                })
              ) : (
                <></>
              )}
            </Container>
          </Grid>
          <Grid item xs={12} sm={6}>
            {project ? (
              <VideoPlayer key={project.id} project={project} />
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper style={{ maxHeight: 400, overflow: "auto" }}>
              {project ? (
                <ItemList key={project.id} project={project} />
              ) : (
                <></>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  button: {
    margin: "1.5% 2%",
    background: "#04a49c",
    "&:hover": {
      background: "#68c8c3",
    },
  },
  buttonIcon: {
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  container: {
    margin: "2% 0",
  },
  input: {
    display: "none",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
