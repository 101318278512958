import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { getProject } from "./store/actions";

export default function Project(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <div>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <OndemandVideoOutlinedIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={props.project.title}
          secondary={props.project.status}
        />
        {props.project.status === "COMPLETED" ? (
          <ListItemSecondaryAction>
            <Button
              edge="end"
              aria-label="comments"
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => dispatch(getProject(props.project.id))}
            >
              View Project
            </Button>
          </ListItemSecondaryAction>
        ) : (
          <></>
        )}
      </ListItem>
      <Divider variant="inset" component="div" />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1.5% 2%",
    background: "#04a49c",
    "&:hover": {
      background: "#68c8c3",
    },
  },
}));
